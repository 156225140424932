var urlSaveContactInformation = "https://implement-sf.com/api/Contact";

$(document).ready(function () {
  $("#submit").on("click", function () {
    saveContactInformation();
  });

  $("#name").keyup(function () {
    $(this).val($(this).val().toUpperCase());
  });

  $("#company").keyup(function () {
    $(this).val($(this).val().toUpperCase());
  });

  $("#email").keyup(function () {
    $(this).val($(this).val().toLowerCase());
  });

  $("#message").keyup(function () {
    $(this).val($(this).val().toUpperCase());
  });
});

var contact = {
  DateContact: "",
  Name: "",
  CompanyName: 0,
  Email: "",
  Message: "",
};

function saveContactInformation() {
  //  BloqueaPantalla();
  getValues();

  if (!validateForm()) {
    DesbloqueaPantalla();
    return;
  }

  if (!validateEmailFormat(contact.Email)) {
    $("#email").val("");
    $("#email").focus();
    // DesbloqueaPantalla();
    return;
  }

  $.ajax({
    type: "POST",
    url: urlSaveContactInformation,
    data: JSON.stringify(contact),
    dataType: "JSON",
    contentType: "application/json; charset=utf-8",
    global: false,
    async: false,
    dataType: "json",

    success: function (jsonData) {
      var xxx = jsonData;
      // alert("Information saved");
      // DesbloqueaPantalla();
      clearTexbox();
      $("#homeisfId").get(0).click();
    },
    error: function (jsonData) {
      var error = jsonData;
      // alert("Something went wrong");
      // DesbloqueaPantalla();
    },
  });
}

function getValues() {
  contact.DateContact = "2023-09-20";
  contact.Name = $("#name").val();
  contact.CompanyName = $("#company").val();
  contact.Email = $("#email").val();
  contact.Message = $("#message").val();
}

function validateEmailFormat(mail) {
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  if (mail.match(mailformat)) {
    $("#email").removeClass("error");
    return true;
  }
  $("#email").addClass("error");
  alert("You have entered an invalid email address!");
  return false;
}

function validateForm() {
  if (!$("#name").val()) {
    $("#name").addClass("error");
    $("#name").focus();
    return false;
  } else {
    $("#name").removeClass("error");
  }

  if (!$("#company").val()) {
    $("#company").addClass("error");
    $("#company").focus();
    return false;
  } else {
    $("#company").removeClass("error");
  }
  return true;
}

function clearTexbox() {
  $("#name").val("");
  $("#company").val("");
  $("#email").val("");
  $("#message").val("");
}
